<template>
  <div>
    <vue-multiselect :value="selectedAnswerChoices"
                     label="text"
                     track-by="id"
                     ref="answer-multiselect"
                     :options="answerChoices"
                     :close-on-select="false"
                     :multiple="multiple"
                     :showLabels="false"
                     :placeholder="staticText.selectAnswerPlaceholder"
                     @select="selectedOption => handleOptionSelect(selectedOption)"
                     @remove="selectedOption => handleOptionChange(selectedOption)"
    >
      <template #tag="{option}">
        <bao-toggle-button
          :is-active="true"
          :label="option.text"
          :type="buttonType"
          button-class="p-2 m-1"
          @toggle="handleOptionChange(option)"
        ></bao-toggle-button>

      </template>

      <template #singleLabel="{option}">
        <bao-toggle-button
          :is-active="true"
          :label="option.text"
          :type="buttonType"
          button-class="p-2"
        ></bao-toggle-button>
      </template>

      <template #option="{option}">
        <div class="d-flex align-items-center justify-content-between">
          <span class="col px-0 text-left text-wrap">
            <bao-toggle-button
              :is-active="option.selected"
              :label="option.text"
              :type="buttonType"
              :button-class="['optionBtn', 'bg-transparent']"
              @toggle="handleOptionChange(option)"
            ></bao-toggle-button>
          </span>
          <!-- Answer Actions display -->
          <span class="column-reverse">
            <slot name="answerActionDisplay" :selectedChoice="option"></slot>
          </span>
        </div>
      </template>
    </vue-multiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import BaoToggleButton from "@/apps/base/BaoToggleButton"

export default {
  name: "AnswerDropdownSelector",
  components: {
    VueMultiselect,
    BaoToggleButton
  },
  props: {
    answerChoices: {
      type: Array
    },
    isSingleChoice: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectedAnswerChoices: {
      type: Array
    }
  },
  data () {
    return {
      staticTextDefault: {
        selectAnswerPlaceholder: "Select Answer"
      }
    }
  },
  computed: {
    buttonType () {
      return !this.multiple ? "radio" : "checkbox"
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    handleOptionSelect (selectedOption) {
      this.handleOptionChange(selectedOption)
      this.closeOnSelect(selectedOption)
    },
    handleOptionChange (selectedOption) {
      this.$emit("changed", selectedOption)
    },
    closeOnSelect (selectedOption) {
      if (this.isSingleChoice || (selectedOption.actions && selectedOption.actions.length > 0)) {
        this.$refs["answer-multiselect"].toggle()
      }
    }

  }
}
</script>
<style scoped lang="scss">

.optionBtn {
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

</style>
