<template>
  <div :id="'answer-choices-' + item.id">
    <div v-if="isDropDown">
      <answer-dropdown-selector
        :selected-answer-choices="selectedAnswerChoices"
        :answer-choices="answerChoices"
        :multiple="isTypeMultiselectDropdown"
        :is-single-choice="isTypeSingleSelectDropdown"
        @changed="selectedOption => toggleAnswerSelection(selectedOption)"
      >
        <template #answerActionDisplay="{selectedChoice}">
          <answer-actions-display
            v-if="selectedChoice.actions && selectedChoice.actions.length>0"
            :unique-id="item.uniqueId"
            :level="level"
            v-model="selectedChoice.actions"
            :parent-container="mainContainer"
            :answer-choice-id="selectedChoice.id"
            class="my-auto"
          ></answer-actions-display>
        </template>
      </answer-dropdown-selector>
    </div>
    <div v-else>
      <div :id="'answer-'+ index"
           v-for="(answerChoice, index) in answerChoices"
           :key="'answer-'+index"
           class="container row py-2 align-items-center flex-nowrap"
      >
        <!--checkbox/radio answer choices-->
        <bao-toggle-button
          :id="'answer-btn-'+ answerChoice.id"
          :is-active="answerChoice.selected"
          :label="answerChoice.text"
          :type="buttonType"
          :button-class="['minBtn']"
          @toggle="toggleAnswerSelection(answerChoice)"
        ></bao-toggle-button>

        <!-- Actions display -->
        <answer-actions-display
          v-if="answerChoice.actions && answerChoice.actions.length>0"
          :unique-id="item.uniqueId"
          :level="level"
          v-model="answerChoice.actions"
          :parent-container="mainContainer"
          :answer-choice-id="answerChoice.id"
          class="mr-3"
        ></answer-actions-display>

        <!-- Importance Slider -->
        <div
          v-if="answerChoice.selected && answerChoice.has_importance"
          class="w-15"
        >
          <vue-slider
            :id="'slider-' + answerChoice.id"
            v-model="answerChoice.importance"
            :dot-size="16"
            :process-style="{background: '#227cfb'}"
            :min="1"
            :max="10"
            :interval="1"
            class="input"
            tooltip-dir="bottom"
            @callback="handleAnswerSelected"
          ></vue-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import vueSlider from "vue-slider-component"
import { currentCallStore } from "@/store/services/callStore"
import AnswerActionsDisplay from "@/apps/talkscript/components/AnswerActionsDisplay"
import AnswerDropdownSelector from "@/apps/call/CallViewItem_components/AnswerDropdownSelector"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import { mapStateToCurrentCallStore } from "../utils"

export default {
  name: "AnswerChoices",
  components: {
    vueSlider,
    AnswerActionsDisplay,
    AnswerDropdownSelector,
    BaoToggleButton
  },
  props: {
    item: { // callItem
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0,
      required: false
    },
    additionalData: {
      type: Object,
      default: null,
      required: false
    },
    mainContainer: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      answerChoices: [],
      selectedAnswerChoices: null
    }
  },
  computed: {
    ...mapStateToCurrentCallStore([
      "displayObjectionModal"
    ]),
    isTypeMultiselect () {
      // item type multiselect was saved as item_type question in database because we had only this type with answers
      // at the beginning. renaming it needs data migration.
      return this.item.item_type === "question"
    },
    isTypeRatedMultiSelect () {
      return this.item.item_type === "rated_multiselect"
    },
    isTypeSingleSelectRadio () {
      return this.item.item_type === "single_select_radio"
    },
    isTypeSingleSelectDropdown () {
      return this.item.item_type === "single_select_dropdown"
    },
    isTypeMultiselectDropdown () {
      return this.item.item_type === "multi_select_dropdown"
    },
    isDropDown () {
      return this.isTypeMultiselectDropdown || this.isTypeSingleSelectDropdown
    },
    buttonType () {
      return this.isTypeSingleSelectRadio ? "radio" : "checkbox"
    },
    canTriggerEndCall () {
      // checking if the item can trigger - "end call" based on its tags
      // tag='item_type/quick_call_end' indicates that the item is of a type that must trigger 'end call'
      if (Array.isArray(this.item.tags) && this.item.tags.length) {
        return this.item.tags.includes("item_type/quick_call_end")
      }
      return false
    }
  },
  watch: {
    "item.selectedAnswers": {
      handler () {
        this.transformAnswerChoices()
      },
      deep: true
    }
  },
  created () {
    this.transformAnswerChoices()
  },
  methods: {
    setInitialAnswers (transformedAnswers) {
      this.selectedAnswerChoices = transformedAnswers.filter(answer => answer.selected === true)
    },
    getSelectedAnswers () {
      let selectedAnswers = this.item.selectedAnswers ? this.item.selectedAnswers : null
      if (!selectedAnswers) {
        selectedAnswers = this.item.call_item ? this.item.call_item.answers : []
      }
      return selectedAnswers
    },
    async transformAnswerChoices () {
      const selectedAnswers = this.getSelectedAnswers()
      const answerChoicesTotal = this.item.answer_choices
      const newAnswerChoices = []
      for await (const answerChoice of answerChoicesTotal) {
        const transformedAnswerActions = answerChoice.actions ? await this.transformAnswerActions(answerChoice.actions) : []
        const transformedAnswer = {
          id: answerChoice.id,
          text: answerChoice.label,
          // set has_importance flag to true if item type is rated_multiselect otherwise set to false.
          has_importance: this.isTypeRatedMultiSelect,
          importance: 5,
          actions: transformedAnswerActions,
          selected: false,
          tags: answerChoice.tags,
          question_id: answerChoice.id
        }
        const selectedAnswer = selectedAnswers.filter(item => item.text === transformedAnswer.text)[0]
        if (selectedAnswer) {
          transformedAnswer.selected = true
          transformedAnswer.importance = selectedAnswer.importance
          transformedAnswer.has_importance = selectedAnswer.has_importance
        }
        newAnswerChoices.push(transformedAnswer)
      }
      this.answerChoices = newAnswerChoices
      // set answerChoices as selectedAnswerChoices after setting selected = true
      // this is done to track selectedAnswerChoices with their ids.
      this.setInitialAnswers(this.answerChoices)
    },
    async transformAnswerActions (answerActions) {
      for (const answerAction of answerActions) {
        let currentObjectionID
        if (answerAction.type === "add_playbook") {
          if (!answerAction.objectId) {
            currentObjectionID = answerAction.data.id
            const { data } = await axios.get(`/api/objectionchoices/${currentObjectionID}`)
            answerAction.objectId = data.talkscript
          }
        } else {
          currentObjectionID = answerAction.data.id
          answerAction.objectId = currentObjectionID
        }
      }
      return answerActions
    },
    toggleAnswerSelection (selectedAnswer) {
      const selectedChoice = this.answerChoices.filter(answerChoice => answerChoice.id === selectedAnswer.id)[0]
      this.$set(selectedChoice, "selected", !selectedChoice.selected)

      if (this.isTypeSingleSelectRadio || this.isTypeSingleSelectDropdown) {
        const notSelectedChoices = this.answerChoices.filter(answerChoice => answerChoice.id !== selectedChoice.id)
        notSelectedChoices.forEach(answerChoice => {
          this.$set(answerChoice, "selected", false)
        })
      }
      this.handleAnswerSelected(selectedChoice)
    },
    async handleAnswerSelected (selectedAnswerChoice = null) {
      const selectedAnswers = this.answerChoices
        .filter(answer => answer.selected)
        .map(({ text, has_importance, importance, tags, question_id }) => ({
          text,
          has_importance,
          importance,
          tags,
          question_id
        }))

      this.$emit("answer-selected", selectedAnswers)

      await this.closeObjectionModalIfNeeded(selectedAnswerChoice.actions)

      if (selectedAnswerChoice && selectedAnswerChoice.selected) {
        if (this.canTriggerEndCall) {
          // if the item is of a type that can trigger end call then, execute the end call method from store
          currentCallStore.endCall(false)
        } else {
          await currentCallStore.handleAnswerActions({
            answerActions: selectedAnswerChoice.actions,
            vueComponent: this,
            answerPBItem: this.item,
            parentContainer: this.mainContainer,
            additionalData: this.additionalData
          })
        }
      }
    },
    closeObjectionModalIfNeeded (answerActions) {
      for (const answerAction of answerActions) {
        if (answerAction.type !== "collapse" && answerAction.type !== "expand") {
          this.$emit("close-objection-modal")
          break
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.minBtn {
  max-width: 80%;
  overflow-wrap: anywhere;
}

</style>
